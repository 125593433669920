<template>
  <div :id="wEditorId"></div>
</template>

<script>
import { base } from './mixin'
import E from 'wangeditor'
import { uploadFile } from '@/mixins/uploadImg'
export default {
  name: 'WangEditor',
  mixins: [base],
  watch: {
    value(val) {
      // 双向数据绑定
      if (!this.hasChange && this.hasInit) {
        console.log(val)
        this.$nextTick(() => {
          this.editor.txt.html(val)
        })
      }
    },
    showStatus(n) {
      if (n) {
        this.$nextTick(() => {
          this.editor.txt.html(this.value)
        })
      }
    }
  },
  methods: {
    init() {
      this.editor = new E(`#${this.wEditorId}`)
      this.contentChangeHandle()
      this.customUploadImgHandle()
      this.otherConfigSet()
      this.editor.create()
      this.hasInit = true
    },
    contentChangeHandle() {
      this.editor.config.onchangeTimeout = 300
      const _this = this
      this.editor.config.onchange = function (newContent) {
        // this.$emit('change', newContent)
        _this.hasChange = true
        _this.$emit('input', newContent)
        _this.$emit('change', newContent)
      }
    },
    customUploadImgHandle() {
      this.editor.config.customUploadImg = async function (resultFiles, insertImgFn) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        const imgUrl = await uploadFile('policy', resultFiles[0])
        console.log(imgUrl)
        // 上传图片，返回结果，将图片插入到编辑器中
        insertImgFn(imgUrl)
      }
    },
    otherConfigSet() {
      this.editor.config.pasteIgnoreImg = true
      this.editor.config.zIndex = 500
      this.editor.config.height = 500
      this.editor.config = {
        ...this.editor.config,
        ...this.customConfig
      }
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    // 销毁编辑器
    this.editor.destroy()
    this.editor = null
  }
}
</script>

<style scoped>
</style>

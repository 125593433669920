export const base = {
    props: {
        wEditorId: {
            type: String,
            default: () => 'vue-wEditor-' + new Date().getTime()
        },
        value: {
            type: String,
            default: ''
        },
        customConfig: {
            type: Object,
            default: () => ({})
        },
        showStatus: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            editor: null,
            hasChange: false,
            hasInit: false
        }
    }
}
